var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('window-header'),_c('nav-form',{attrs:{"nuevo":false,"editar":false,"eliminar":false}}),_c('section',[_c('article',{staticStyle:{"height":"395px","width":"1200px"}},[_c('img',{staticStyle:{"position":"absolute","width":"40px","height":"40px","top":"4px","left":"6px"},attrs:{"src":_vm.formData.imagen && typeof _vm.formData.imagen == 'object'
            ? _vm.formData.imagen.imagen
            : require('./../assets/noimage.png')}}),_c('field',{staticStyle:{"top":"5px","left":"68px"},attrs:{"name":"codigo","widget":"char","searchable":"","label":"Código","help":"Código del cliente","placeholder":"Cód. cliente","width":"100px","readonly":_vm.mode == 'edit',"inputStyle":"font-weight:bold;text-align:center;"}}),_c('field',{staticStyle:{"top":"5px","left":"180px"},attrs:{"name":"nombre_comercial","widget":"char","searchable":"","label":"Nombre comercial","help":"Nombre comercial del cliente","required":"","placeholder":"Nombre comercial del cliente","width":"300px"}}),_c('r-tabs',{ref:"main-tabs",staticStyle:{"top":"40px","height":"355px"},attrs:{"wt":_vm.wt,"buttons":[
          'Movimientos',
          'Pedidos',
          'Albaranes',
          'Facturas',
          'Vencimientos' ]}},[_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"movimientos","structureRef":"consulta_articulos_por_cliente.movimientos","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              {
                name: 'tipo',
                header: 'Tipo',
                type: 'select',
                options: {
                  factura_cliente: 'FV - Factura de Cliente',
                  albaran_cliente: 'AV - Albaran de Cliente',
                  condicional_cliente: 'CV - Condicional de Cliente',
                  pedido_cliente: 'PV - Pedido de Cliente',
                  factura_proveedor: 'FC - Factura de Proveedor',
                  albaran_proveedor: 'AC - Albaran de Proveedor',
                  condicional_proveedor: 'CC - Condicional de Proveedor',
                  pedido_proveedor: 'PC - Pedido de Proveedor',
                  deposito_representante: 'DR - Deposito de Representante',
                  regularizacion: 'RG - Regularización',
                },
              },
              { name: 'ndoc', header: 'Doc.' },
              {
                name: 'origen_id',
                header: 'Origen',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              {
                name: 'destino_id',
                header: 'Destino',
                type: 'm2o',
                primary: 'codigo',
                label: 'nombre',
                showCode: true,
              },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"pedidos","structureRef":"consulta_articulos_por_cliente.pedidos","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              { name: 'ndoc', header: 'Doc.' },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"albaranes","structureRef":"consulta_articulos_por_cliente.albaranes","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              {
                name: 'tipo',
                header: 'Tipo',
                type: 'select',
                options: {
                  albaran_cliente: 'A - Albaran',
                  condicional_cliente: 'C - Condicional',
                },
              },
              { name: 'ndoc', header: 'Doc.' },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"facturas","structureRef":"consulta_articulos_por_cliente.facturas","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              { name: 'ndoc', header: 'Doc.' },
              { name: 'descripcion', header: 'Descripción' },
              {
                name: 'unidades',
                header: 'Unid.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'peso',
                header: 'Peso',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'descuentos',
                header: 'Dtos.',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
                sufix: '%',
              },
              {
                name: 'precio',
                header: 'Precio',
                type: 'numeric',
                numericFormat: { pattern: '0,0.00' },
              },
              {
                name: 'divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1),_c('div',{staticClass:"tab"},[_c('field',{staticStyle:{"top":"2px","left":"2px","width":"1170px"},attrs:{"name":"vencimientos","structureRef":"consulta_articulos_por_cliente.vencimientos","widget":"handsontable","height":309,"width":1170,"minRows":13,"readonly":"","htSettings":{ columnSorting: true },"columns":[
              {
                name: 'factura_id.formapago_id',
                header: 'F.pago',
                type: 'm2o',
                primary: 'codigo',
                label: 'descripcion',
                showCode: true,
              },
              { name: 'fecha', type: 'date2', header: 'Fecha' },
              { name: 'factura_id.ndoc', header: 'Doc.' },
              {
                name: 'importe',
                header: 'Importe',
                type: 'numeric',
                numericFormat: { pattern: '0,0.000' },
              },
              {
                name: 'factura_id.divisa_id',
                header: 'Divisa',
                type: 'm2o',
                primary: 'codigo',
                label: 'codigo',
              } ]}})],1)])],1)]),_c('v-collapse-wrapper',{ref:"main-collapse",attrs:{"active":true}},[_c('div',{directives:[{name:"collapse-toggle",rawName:"v-collapse-toggle"}],staticClass:"header"},[_vm._v(" Listado "),_c('div',{staticClass:"item-after"},[_vm._v(_vm._s(_vm.count))])]),_c('div',{directives:[{name:"collapse-content",rawName:"v-collapse-content"}],staticClass:"my-content"},[_c('hot-table',{ref:"hotTableComponent",attrs:{"settings":_vm.htSettings}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }